<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y:scroll; z-index: 100;">
		<BHLoading :show="loading" />
		<TemplateDrawer @next="emailBuilt" />
		<a-modal :visible="showConfirmDialog" :footer="null" @cancel="showConfirmDialog = false">
			<div style="text-align:center">
				<div>
					<Rive v-if="!sendNow" src="schedule.riv" fit="cover" style="width:100%;" />
					<img v-else :src="sendNow ? sendImg : scheduleImg" style="max-width:270px; width:100%;" />
				</div>
				<div style="text-align:center; font-size:18px" class="mt-3">
					<span>
						{{ sendNow ? `You are about to send this broadcast now to` : `This scheduled broadcast will be sent
						to`}}<br />
					</span>

					<span style="display:block; font-size:24px; font-weight:bold; margin-top:10px;">{{ broadcastContacts
					}}</span>
					<span style="font-weight:bold;">Contacts</span>
					<template v-if="!sendNow && broadcast.scheduledString">
						<div style="font-size:18px;" class="mt-4">
							<div>Scheduled for</div>
							<div><b>{{ broadcast.scheduledString }}</b></div>
						</div>
					</template>

					<small class="send-disclaimer">Number shown might change based on valid emails or non reputable email
						sources. Please read the following for more information.</small>

					<div class="dF jC mt-6">
						<a-button v-if="broadcastContacts <= 0" class="ml-2" size="large" @click="showConfirmDialog = false"
							type="primary">CLOSE</a-button>
						<a-button v-else class="ml-2" size="large" @click="submit" :disabled="broadcastContacts < 0"
							type="primary">SEND</a-button>

					</div>
				</div>
			</div>
		</a-modal>
		<div class="dF jSB aS">
			<h4 class="pb-2">Edit Broadcast</h4>
			<div v-if="broadcast.isScheduled" class="px-3 py-1" style="background-color: #FFFFFF; border: 1px solid #e8e8e8;">
				<div>We have <span style="font-weight: bold; color: red;">removed</span> broadcast <span style="font-weight: bold;">Schedule Time</span> to avoid sending it to users while you are editing. Please Reschedule it at your convenience.</div>
			</div>
		</div>

		<div :class="[emailType === 'Choose a starting point' ? 'contactspage2' : '']" style="background-color: white;">
			<a-steps v-if="current === 1" :current="current" class="mb-5"
				style="width: 70%; padding-top: 25px; padding-left: 25px;">
				<a-step v-for="item in steps" :key="item.title" :title="item.title">
					<span slot="description">{{ item.description }}</span>
				</a-step>
			</a-steps>

			<a-card v-if="current === 0">
				<a-steps :current="current" class="mb-5" style="width: 100%; padding-right: 25px;">
					<a-step v-for="item in steps" :key="item.title" :title="item.title">
						<span slot="description">{{ item.description }}</span>
					</a-step>
				</a-steps>

				<a-form-model ref="ruleForm" :model="broadcast" :rules="rules">
					<a-form-model-item ref="name" label="Name this broadcast" prop="name">
						<a-input v-model="broadcast.name" placeholder="Add broadcast name" size="large"
							style="width: 70%;" />
					</a-form-model-item>

					<a-form-model-item ref="subject" label="Subject line" prop="subject">
						<a-input v-model="broadcast.subject" placeholder="Add subject line" size="large"
							style="width: 70%;" />
					</a-form-model-item>

					<a-form-model-item ref="preheader" label="Preheader text" prop="preheader">
						<a-input v-model="broadcast.preheader"
							placeholder="The preview that's displayed as an email preview" size="large"
							style="width: 70%;" />
					</a-form-model-item>

					<a-form-model-item label="Who is it from?" style="width: 70%;">
						<a-row :gutter="16">
							<a-col :span="8">
								<a-input :value="sender.name" size="large" disabled />
							</a-col>
							<a-col :span="16">
								<a-select :value="sender.id" size="large" @change="selectSender">
									<a-select-option v-for="item in senders" :key="item.id" :value="item.id"
										:disabled="!item.active">{{ item.email }}</a-select-option>
								</a-select>
							</a-col>
						</a-row>
					</a-form-model-item>
				</a-form-model>
				<div class="mt-5" style="width: 100%; text-align: right;">
					<a-button @click="cancelBroadcast" class="mr-3" style="width: 100px; height: 35px;">CANCEL</a-button>
					<a-button @click="nextStep" type="primary" style="width: 100px; height: 35px;">NEXT</a-button>
				</div>
			</a-card>

			<a-card v-if="current === 2">
				<a-steps :current="current" class="mb-5" style="width: 100%; padding-right: 25px;">
					<a-step v-for="item in steps" :key="item.title" :title="item.title">
						<span slot="description">{{ item.description }}</span>
					</a-step>
				</a-steps>

				<h5 class="mb-5">Who are you sending this Broadcast to?</h5>

				<a-form-model ref="step3form" :model="broadcast" :rules="step3Rules">
					<a-form-model-item ref="inc" label="Send to All Contacts" prop="allContacts">
						<a-switch v-model="broadcast.allContacts" />
					</a-form-model-item>
					<p>Send to </p>
					<div class="mb-4">
						<a-radio-group v-model="isAgent" :disabled="broadcast.allContacts">
							<a-radio-button :value="'all'">
								All
							</a-radio-button>
							<a-radio-button :value="true">
								Realtors
							</a-radio-button>
							<a-radio-button :value="false">
								Non Realtors
							</a-radio-button>
						</a-radio-group>
					</div>
					<a-form-model-item ref="inc" label="Include Recipient by Tags" prop="tagI_any">
						<a-select :disabled="broadcast.allContacts" v-model="broadcast.tagI_any" mode="multiple"
							style="width: 70%" @search="handleSearch" :filter-option="false">
							<a-select-option v-for="(option, optionI) in filteredTags" :key="option + optionI"
								:value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item ref="exclude" label="Exclude Recipient by Tags" prop="tagE_any">
						<div style="width: 100%; color: #9EA0A5;line-height: 1;margin-bottom: 15px;">Exclude anyone in this
							list from receiving this broadcast</div>
						<a-select :disabled="broadcast.allContacts" v-model="broadcast.tagE_any" mode="multiple"
							style="width: 70%" size="large" @search="handleSearch" :filter-option="false">
							<a-select-option v-for="(option, optionI) in filteredTags" :key="option + optionI"
								:value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
				<a-alert v-if="contactsNotFound" class="mt-3" type="error" show-icon style="width: fit-content;">
					<template slot="message">
						No contacts were found. Please review and try again.
					</template>
				</a-alert>
				<div class="mt-5" style="width: 100%; text-align: right;">
					<a-button @click="backToEditor" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
					<a-button @click="lastStep" type="primary" style="width: 100px; height: 35px;">NEXT</a-button>
				</div>
			</a-card>

			<a-card v-if="current === 3">
				<a-steps :current="current" class="mb-5" style="width: 70%; padding-right: 25px;">
					<a-step v-for="item in steps" :key="item.title" :title="item.title">
						<span slot="description">{{ item.description }}</span>
					</a-step>
				</a-steps>

				<a-form-model-item class="mt-3">
					<template slot="label">
						Use Send Time Optimization
						<a-tooltip placement="right" overlayClassName="change-tooltip-color">
							<template slot="title" style="width: 400px;">
								Improve your email conversion and open rate by automatically sending emails at the exact moment a recipient views their inbox with Send Time Optimization based on recipients previous engagements.
							</template>
							<a-icon type="question-circle"
								style="font-size: 14px; color: black; margin-left: 2px;" />
						</a-tooltip>
					</template>
	                <a-radio-group v-model="broadcast.useSendTimeOptimization">
	                    <a-radio-button :value="true">
	                        Yes
	                    </a-radio-button>
	                    <a-radio-button :value="false">
	                        No
	                    </a-radio-button>
	                </a-radio-group>
	            </a-form-model-item>

				<h5 class="mb-3">When would you like to send this broadcast?</h5>

				<a-radio-group v-model="sendNow">
					<a-radio style="display: block; height: 30px; line-height: 30px;" :value="true">
						Send it now
					</a-radio>
					<a-radio style="display: block; height: 30px; line-height: 30px;" :value="false">
						Schedule a specific time and date
					</a-radio>
				</a-radio-group>

				<template v-if="!sendNow">
					<a-form class="mt-2" style="margin-left:1.8rem">
						<a-form-item label="Date" class="mb-2">
							<a-date-picker v-model="broadcast.scheduledDate" @change="checkSchedule"
								placeholder="Select Date" />
						</a-form-item>
						<a-form-item label="Time">
							<a-time-picker v-model="broadcast.scheduledTime" @change="checkSchedule" use12-hours
								:minute-step="10" :default-value="moment('12:00', 'hh:mm A')" format="h:mm a" />
						</a-form-item>
						<a-form-item label="Time Zone">
							<div>
								<a-select v-model="selectedCountry" style="width: 70%; max-width:300px;"
									:options="countries" />
							</div>
							<div>
								<a-select v-model="broadcast.timezone" @change="checkSchedule"
									style="width: 70%;  max-width:300px;">
									<a-select-option v-for="(item, itemI) in timezones" :key="itemI" :value="item.value">{{
										item.label }}</a-select-option>
								</a-select>
							</div>
						</a-form-item>
					</a-form>
					<a-alert v-if="scheduleError" class="mt-3" type="error" show-icon style="width: fit-content;">
						<template slot="message">
							Error: {{ scheduleError }}
						</template>
					</a-alert>
				</template>

				<template v-if="false">
					<h5 class="mt-5 mb-4">Confirmation email</h5>
					<a-input class="mb-5" :value="sender.email" style="width: 70%;" disabled />
				</template>

				<div class="mt-5" style="width: 100%; text-align: right;">
					<a-button @click="current = 2" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
					<a-tooltip overlayClassName="change-tooltip-color" :title="$p < 40 ? 'You do not have permission to send out Broadcasts' : ''">
						<a-button @click="showConfirm" type="primary" style="width: 100px; height: 35px;"
							:disabled="$p < 40 || (!sendNow && Boolean(scheduleError))">SEND</a-button>
					</a-tooltip>
				</div>
			</a-card>
		</div>
	</div>
</template>

<script>
import { arrToObj } from 'bh-mod'
import Rive from '@/components/common/Rive'
import TemplateDrawer from '@/components/email/TemplateDrawer'
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment';
import axios from 'axios'
import { getTimezones, getCountries } from "@/assets/timezones";

export default {
	components: {
		TemplateDrawer, BHLoading, Rive
	},
	data() {
		const validateAllContacts = (rule, value, callback) => {
			this.$refs.step3form && this.$refs.step3form.validateField('tagI_any');
			callback();
		}
		return {
			loading: false,
			sendImg: require('@/assets/sendEmail.svg'),
			scheduleImg: require('@/assets/schedule.svg'),
			scheduleError: false,
			selectedCountry: 'CA',
			isAgent:'all',
			contactsNotFound: false,
			sendNow: true,
			showConfirmDialog: false,
			broadcastContacts: -2,
			tags: [],
			filteredTags: [],
			filter: 'all',
			sender: {
				id: '',
				name: '',
				email: ''
			},
			broadcast: {
				name: '',
				subject: '',
				previewText: '',
				timezone: '-04:00',
				allContacts: true,
				tagI_any: [],
				tagE_any: [],
				scheduled: 0,
				scheduledString: '',
				isScheduled: false,
				scheduledTime: moment().endOf('hour').add(1, 'hour').add(1, 'minutes'),
				scheduledDate: moment()
			},
			rules: {
				name: [
					{ required: true, message: 'Please input a broadcast name', trigger: 'blur' },
				],
				subject: [
					{ required: true, message: 'Please enter a subject line', trigger: 'blur' }
				],
				recipient: [
					{ required: true, message: 'Please include at least one recipient list', trigger: 'blur' }
				]
			},
			step3Rules: {
				allContacts: [
					{ validator:validateAllContacts,trigger: 'change' }
				],
			},
			current: 0,
			steps: [
				{
					title: 'Step 1',
					description: 'Define Broadcast Info'
				},
				{
					title: 'Step 2',
					description: 'Content Design'
				},
				{
					title: 'Step 3',
					description: 'Define Recipients'
				},
				{
					title: 'Finish',
					description: 'Deliver'
				},
			],
		}
	},
	watch: {
		selectedCountry(val) {
			this.broadcast.timezone = this.timezones[0].value
		},
		currentStep() {
			this.current = 2
		},
	},
	computed: {
		countries() { return getCountries() },
		timezones() { return getTimezones(this.selectedCountry) },
		getTime() {
			// find out if moment object is today
			let day = this.broadcast.scheduledDate.format('dddd')
			let time = this.broadcast.scheduledTime.format('h:mm A')
			if (this.broadcast.scheduledDate.isSame(moment(), 'day')) {
				day = 'Today'
			}
			return `${day} @ ${time}`
		},
		currentStep() {
			return this.$store.state.step
		},
		senders() {
			let senders = this.$store.state.email.senders
			return senders
		},
		emailType() {
			return this.$store.state.emailType
		},
		broadcasts() {
			return this.$store.state.email.broadcasts
		},
		templates() {
			let newArray = []
			let templates = Object.values(this.$store.state.email.templates)

			let blank = templates.find(x => x.name.includes('Blank T'))
			if (blank) newArray.push(blank), templates = templates.filter(x => !x.name.includes('Blank T'))
			newArray = [...newArray, ...templates]

			// return newArray
			return arrToObj(newArray)
		},
		drafts() {
			return this.$store.state.email.drafts
		},
		siteURL() {
			return this.$store.state.email.siteURL
		},
		instance() {
			return this.$store.state.instance
		},
	},
	methods: {
		handleSearch(value) {
			const inputValue = value.toLowerCase();
			this.filteredTags = this.tags.filter(tag =>
				tag.label.toLowerCase().includes(inputValue)
			);
		},
		selectSender(sender) {
			this.sender.id = this.senders[sender].id
			this.sender.name = this.senders[sender].name
			this.sender.email = this.senders[sender].email
		},
		showConfirm() {
			if (this.sendNow) {
				this.broadcast.scheduledString = ''
			} else {
				this.checkSchedule()
			}

			if (!this.sendNow && Boolean(this.scheduleError)) return this.$message.error('Invalid Time. Please review your fields and try again')
			this.showConfirmDialog = true
		},
		getContactsNum(){
			return new Promise( (res,rej) => {
				this.broadcastContacts = -1;
				const sendOBJ = JSON.parse(JSON.stringify(this.broadcast));
				if(this.isAgent !== 'all'){
					sendOBJ.isAgent = this.isAgent;
				}
				this.$api.post(`/broadcasts/:instance/contactscount`,sendOBJ).then( ({data}) => {
					data = +data || 0
					this.broadcastContacts = data
					return res(data)
				})
				.catch( (err) => {
					return res(0)
				})
			})
		},
		checkSchedule() {
			this.scheduleError = false
			try {
				let date = this.broadcast.scheduledDate.format('L');
				let time = this.broadcast.scheduledTime.format('HH:mm');
				let timezone = this.broadcast.timezone
				let scheduledString = `${date} ${time} ${timezone}`
				this.broadcast.scheduledString = scheduledString
				let scheduled = moment(scheduledString)

				if (scheduled < moment()) throw new Error('Scheduled time is in the past')
				if (scheduled < moment().add(3, 'minute')) throw new Error('Scheduled time is too close')
				if (scheduled > moment().add(1, 'month')) throw new Error('Scheduled time is too far')
			} catch (err) {
				this.scheduleError = err.message
			}
		},

		emailBuilt(email) {
			this.email = email
		},
		backToEditor() {
			this.$store.commit('SHOW_EDIT_TEMPLATE', this.email)
		},
		moment,
		async submit() {
			if (!this.sendNow) {
				if (this.scheduleError) return console.error('time is invalid')
			}

			let sendOBJ = this.email
			sendOBJ.SUBJECT = this.broadcast.subject
			sendOBJ.PREVIEW = this.broadcast.preheader

			this.$store.commit('LOAD')
			let { data: html } = await axios.post(`${this.siteURL}/email?final=1`, sendOBJ)
			if (typeof html !== 'string') return this.$store.commit('LOAD', false), this.$message.error('There was a problem creating HTML')

			sendOBJ = JSON.parse(JSON.stringify(this.broadcast))
			sendOBJ.from = this.sender.id
			sendOBJ.project = this.email.id
			sendOBJ.prodHtml = html
			sendOBJ.scheduled = this.sendNow ? 0 : new Date(sendOBJ.scheduled).getTime()

			if(this.isAgent !== 'all'){
				sendOBJ.isAgent = this.isAgent;
			}

			this.$api.post(`/broadcasts/:instance`, sendOBJ).then(({ data }) => {
				this.$store.commit('LOAD', false)
				if (this.sendNow) {
					data.processing = true
					this.$store.commit('SET_PROP', { where: ['filter'], what: 'Processing' })
				} else {
					this.$store.commit('SET_PROP', { where: ['filter'], what: 'Scheduled' })
				}
				this.$store.commit('SET_PROP', { where: ['broadcasts', data.id], what: data })
				this.$router.push('/')
			}).catch(err => {
				this.$store.commit('LOAD', false)
				this.$message.error('There was an error while dispatching your email')
			})
		},
		async lastStep() {
			this.$refs.step3form.validate(async valid => {
				this.contactsNotFound = false
				if (valid) {
					this.fetchContactCounts();
				} else {
					return false;
				}
			});
		},

		async fetchContactCounts() {
				this.$store.commit('LOAD', true)
				let num = await this.getContactsNum()
				if (num) this.current = 3
				else {
					this.contactsNotFound = true
					this.$message.error('Sorry no contacts were found for your selection. Please review and try again.')
				}
				this.$store.commit('LOAD', false)
			},

		nextStep() {
			if (this.current == 0) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let sendObj = JSON.parse(JSON.stringify(this.broadcast))
						sendObj.contacts = []
						sendObj.scheduledString = ''
						this.loading = true
						this.$api.put(`/broadcasts/:instance/${this.broadcast.id}`, sendObj).then(({ data }) => {
							this.$api.get(`/projects/:instance/${data.project}`).then(({ data: email }) => {
								email.page = email.pages[0]
								delete email.pages
								this.email = email
								email.SUBJECT = this.broadcast.subject
								email.PREVIEW = this.broadcast.preheader
								this.$store.commit('SHOW_EDIT_TEMPLATE', email)
								this.loading = false
							}).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						this.loading = false;
						this.$message.error('Something went wrong!');
						return false;
					}
				});
			}
		},
		cancelBroadcast() {
			this.$router.push(`/`)
			this.$store.commit('EMAIL_TYPE', { value: 'Drafts' })
		},
	},
	mounted() {
		if (location.search.includes('edit')) {
			this.nextStep()
		}
	},
	created() {
		this.$api.get(`/tags/:instance?type=contacts`).then(({ data }) => {
			let tags = data.filter(x => x.type === "contacts").map(({ id, name }) => ({ label: name, value: id }))
			this.tags = JSON.parse(JSON.stringify(tags))
			this.filteredTags = JSON.parse(JSON.stringify(tags))
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
		this.$store.commit('EMAIL_TYPE', { value: 'Edit Broadcast' })

		let id = this.$route.params.id

		let broadcasts = []


		if(this.$route.path.includes('scheduled')){
			broadcasts = this.$store.getters.scheduled
		} else {
			broadcasts = this.$store.getters.drafts
		}

		let found = broadcasts.find(item => {
			return item.id === id
		})

		if (found) {
			this.broadcast = { ...this.broadcast, ...JSON.parse(JSON.stringify(found)) }

			if (found.scheduledString) {
				let scheduled = moment(found.scheduledString)

				if (scheduled >= moment().add(3, 'minute')) {
					let s = found.scheduledString.split(' ');
					let scheduledDate = s[0] && moment(s[0]) || moment();
					let scheduledTime = s[1] && moment(s[1], 'hh:mm A') || moment().endOf('hour').add(1, 'hour').add(1, 'minutes');
					let timezone = s[2] || this.timezones[0].value;

					this.broadcast.scheduledDate = scheduledDate;
					this.broadcast.scheduledTime = scheduledTime
					this.broadcast.timezone = timezone
					this.sendNow = false;
				}
			}
		}

		let senders = Object.values(this.$store.state.email.senders)
		if (senders && senders.length) {
			this.sender = {
				id: senders[0].id,
				name: senders[0].name,
				email: senders[0].email
			}
		}
	}
}
</script>



<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.send-disclaimer {
	line-height: 1.2;
	display: block;
	font-size: 12px;
	max-width: 400px;
	margin: 10px auto;
	color: var(--med-gray);
}

.contactspage2 {
	@media screen and (min-width:567px) {
		max-width: calc(100% - 35px) !important;
	}
}

.contacts-page {
	@media screen and (min-width:567px) {
		max-width: 70%;
	}
}

.aB {
	align-items: baseline;
}

.row-mb {
	[class^=ant-col] {
		margin-bottom: 20px;
	}
}
</style>
